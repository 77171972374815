<template>
  <form @submit.prevent="handleSubmit(!v$.$invalid)" class="_form _relative">
    <Loader isOverlay v-if="state.isLoading" />
    <label class="p-field mb-4" :class="{'p-invalid': v$.title.$invalid && v$.title.$dirty}">
      <span class="p-label"> <span v-t="'company_page.company_name'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.title.$model"
        @blur="v$.title.$touch()"
      />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.email.$invalid && v$.email.$dirty}">
      <span class="p-label"> <span v-t="'email'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.email.$model"
        @blur="v$.email.$touch()"
      />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.address.$invalid && v$.address.$dirty}">
      <span class="p-label"> <span v-t="'address'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.address.$model"
        @blur="v$.address.$touch()"
      />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.name.$invalid && v$.name.$dirty}">
      <span class="p-label"> <span v-t="'company_page.contact_person'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.name.$model"
        @blur="v$.name.$touch()"
      />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.phone.$invalid && v$.phone.$dirty}">
      <span class="p-label"> <span v-t="'company_page.phone'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.phone.$model"
        @blur="v$.phone.$touch()"
      />
    </label>
    <label class="p-field mb-4">
      <span class="p-label" v-t="'company_page.credits'"></span>
      <InputNumber type="text" v-model="v$.credits.$model" />
    </label>
    <label class="p-field mb-4">
      <span class="p-label" v-t="'company_page.available_tokens'"></span>
      <InputNumber type="text" v-model="v$.tokens.$model" />
    </label>

    <div class="grid">
      <Button class="p-button-big col mt-3 ml-2 mr-2" type="submit" :disabled="state.isSubmiting"><span v-t="'save_change'"></span></Button>
      <Button class="p-button-big p-button-outlined p-button-gray p-button-icon-right col mt-3 ml-2 mr-2" @click="toggleBlock">
        <span v-t="state.data.is_active ?  'unlock' : 'block'"></span>
        <Icon :name="state.data.is_active ? 'lock' : 'unlock'" />
      </Button>
    </div>
  </form>
</template>

<script>

import { ref, reactive, watch, computed } from 'vue';
import { useStore } from 'vuex'

import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";

import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useVuelidate } from "@vuelidate/core";
import { email, required, minLength } from "@vuelidate/validators";

import { Icon, Loader } from '../../../components/ui'

export default {
  components: {
    InputNumber,
    InputText,
    Button,
    Icon,
    Loader,
  },
  setup() {
    const store = useStore()

    const { t } = useI18n()
    const toast = useToast()

    const isChangePassword = ref(false);
    const state = store.state.company;
    const data = computed(() => state.data);

    const rules = reactive({
      title: { required },
      email: { required, email },
      name: { required },
      phone: { required },
      address: { required },
      credits: {  },
      tokens: {  },
      is_active: {  },
    });

    const v$ = useVuelidate(rules, data)

    const handleSubmit = (isFormValid) => {
      v$.value.$touch()
      if (!isFormValid) {
        return;
      }
      store.dispatch('company/sendBase', { body: data.value, toast, t })
    }


    const toggleBlock = () => {
      store.dispatch('company/reqToggleBlock')
    }

    const addCredits = () => {
      store.dispatch('company/reqAddCredits', {amount: 50, toast, t})
    }

    return {
      v$,
      state,
      isChangePassword,
      handleSubmit,
      toggleBlock,
    }
  },
}
</script>

<style lang="scss" module>
  .password {
    padding: 24px;
    border-radius: $radius;
    background-color: $primary_light_grey;
  }
</style>